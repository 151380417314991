.site-overlay{
  position: fixed;
  width: 2000px;
  height: 2000px;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 3000;
  //display: none;
}

.overlay-form{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 800px;
  height: 600px;
  background-color: #fff;
  z-index: 3100;
  border-radius: 15px;
  box-shadow: 10px 10px 30px black;
  padding: 0px 30px;
  //display: none;

  h2{
    text-align: center;
  }
} //overlay-form

  .contact-form{
    margin-top: 50px;
    width: 100%;

    input[type="text"], input[type="email"], input[type="submit"], textarea{
      width: 100%;
      border: none;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 400;
      background-color: #ededed;
      padding: 0px 20px 0 50px;
      box-shadow: 3px 2px 5px rgba(0,0,0,0.05) inset;
      border: solid 1px #dfdfdf;
      margin-bottom: 15px;
    } //text email textarea

    input[type="submit"]{
      height: 50px;
      background-color: $brand-color;
      color: #fff;
      //position: absolute;
      bottom: 0;
      text-align: center;
    }

    .submit-container{
      height: 150px;
    } //submit-container

    input[type="text"], input[type="email"]{
      height: 50px;
    } //text email

    textarea{
      resize: none;
      height: 150px;
      padding-top: 10px;
    }
  } //form

.contact-prev-text{
  margin-top: 40px;
  p{
    color: #000;
    font-weight: 400;
    font-size: 20px;

    span{
      font-weight: 700;
    } //span
  } //p
} //contact-prev-text
