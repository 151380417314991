.header-mobile{
  display: none;
  padding: 10px;

  @include respond-to('medium'){
    display: block;
  }

  .header-bg{
    height: 70px;
    width: 100%;
    background-color: #fff;
    background: linear-gradient(to bottom, #fff 0, #fff 75%, $brand-color 75%, $brand-color 100% );
    position: absolute;
    top: 0;
    left: 0;
  } //header-bg

  .header-logo{
    position: relative;
    z-index: 1500;
    width: 225px;
    display: inline-block;
    overflow: hidden;

    @include respond-to('smaller'){
      width: 45px;
    }

    img{
      width: 225px;
      max-width: unset;
    }
  } //header-logo

  .header-contacts{
    position: relative;
    z-index: 1500;
    margin-right: 10%;
    display: inline-block;

    @include respond-to('smaller'){
      margin-right: 10px;
      top: 3px;
    }

    a{
      float: left;
      margin-right: 10px;

      @include respond-to('smaller'){
        width: 20px;
        top: -3px;
        position: relative;
      }
    } //a

    span{
      font-weight: 800;
      font-size: 23px;

      @include respond-to('smaller'){
        font-size: 18px;
      }
    } //span
  } //header-contacts
} //header-mobile
