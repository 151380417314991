// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// Regular font family
/// @type List
$text-font-stack: 'Assistant', sans-serif !default;
$custom-font-stack: 'oe_rutz_promedium', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
/// @type Color
$main-color: #000000 !default;
$text-color: #551900 !default;
$title-color: #5b5f6b !default;
$btn-color: #3a6f8f !default;
$brand-color: #116089 !default;
$light-red-color: #bf311a !default;
$color-red: #bf311a !default;
$red-color: #bf311a !default;
$mid-grey-color: rgb(153, 153, 153) !default;
$dark-red-color: #551900 !default;

// real width = 1200
//@type general
$base-zindex: 100;
$max-width: 1230px !default;
$center-margin: 0 auto;
$padding-base: 15px;
$padding-base-box: 20px;
//header
$header-position: fixed !default;
$site-default-top-padding: 90px !default;
$site-mobile-default-top-padding: 90px !default;
$section-spacer-height:20px !default;
$row-spacer-height:20px !default;
//hr
$hr-full-height: 1px !default;
$hr-full-bg: #c1c1c1!default;
$hr-border-color: #fff !default;
$hr-margin:20px 0px !default;
//inner page
$inner-content-holder-width: 77.5% !default;
$inner-content-aside-width: 22.5% !default;
//footer
$footer-padding-top:60px !default;
$footer-min-height:360px !default;
$footer-bg:#fff !default;
//@type breakpoints
$breakpoints: (
  'extra-small': (max-width: 480px),
  'smaller': (max-width: 600px),
  'small': (max-width: 768px),
  'medium': (max-width: 992px),
  'large': (max-width: 1200px),
) !default;
//@type font sizes
$font-size-base:          18px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

p{
  font-size: 17px;
}
