.results-row{
  width: 100%;
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
  background-color: #fff;
  padding: 0 ;
  border-radius: 5px;
  margin:0 0 10px 0;
  transition: .2s;

  .results-row-info{
    padding: 15px 0;
    width: 8.3333%;
    float: right;

    @include respond-to('large'){
      width: 7.5%;
    }
    @include respond-to('medium'){
      width: 9%;
        &:last-of-type{
          span{
            &:first-of-type{
              border-right: solid 1px #cecece;
              border-bottom: none;
              padding-bottom: 35px;
              margin-bottom: 0;
            } //1st
            &:last-of-type{
              border-right: solid 1px #cecece;
            } //last
          } //span
        } //last
    }
    @include respond-to('small'){
      width: 25%;
      &:last-of-type{
        span{
          &:first-of-type{
            border-right: none;
            border-bottom: solid 1px #cecece;
            padding-bottom: 10px;
            margin-bottom: 10px;
          } //1st
          &:last-of-type{
            border-right: none;
          } //last
        } //span
      } //last
    }

    span{
      color: #000;
      font-size: 16px;
      font-weight: 600;
      display: block;
      padding-right: 10px;

      @include respond-to('large'){
        font-size: 15px;
        padding-right: 0px;
      }
      @include respond-to('medium'){
        font-size: 16px;
        padding-right: 10px;
      }
      @include respond-to('small'){
        font-size: 12px;
      }

    /*  &:first-of-type{
        color: #777777;
        font-size: 15px;
        font-weight: 500;
        border-bottom: solid 1px #cecece;
        padding-bottom: 15px;
        margin-bottom: 20px;

        @include respond-to('large'){
          font-size: 13px;
          font-weight: 600;
        }
        @include respond-to('small'){
          font-size: 13px;
          padding-right: 10px;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        @include respond-to('extra-small'){
          font-size: 11px;
          padding-right: 5px;
        }
      } */
    } //span


  } //results-row-info

  &:hover{
    box-shadow: 0 0 20px rgba(0,0,0,0.4);
  } //hover
  .result-pic-container{
    width: 100%;

  .result-pic{
    padding: 10px 10px 10px 0;
    margin-left: 40px;
    //width: 16.6666%;
    width: 5%;
    float: right;
    max-width: 160px;
    position: relative;

    @include respond-to('medium'){
      width: 50%;
      padding: 10px;
    }
    @include respond-to('small'){
      width: 100%;
      max-width: unset;
    }

    img{
      @include respond-to('medium'){
        width: 96%;
        display: block;
        margin: 0 auto;
      }
    }

    &:hover{
      .floating-image{
        z-index: 1;
        opacity: 1;
      }
    }
  } //result-pic

  .floating-image{
    position: absolute;
    top: -5px;
    right: 50px;
    width: 230px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,.15);
    z-index: -1;
    opacity: 0;
    transition: .2s;

    img{
      width: 100%;
    }

  }
}// result-pic-container

  .result-price{
    border-right: solid 1px #cecece;
    //height: 100%;
    display: block;
    //padding: 10px 20px 15px;
    padding: 2px 20px 10px;
    width: 230px;
    float: left;

    @include respond-to('medium'){
      width: 100%;
    }
    span{
      @include respond-to('medium'){
        float: right;
        display: inline-block;
      }
      &:first-of-type{
        font-size: 16px;
        color: #777777;
        display: none;
      } //first-of-type
      &:nth-of-type(2){
        //font-size: 32px;
        font-size: 17px;
        margin-top: 10px;
        font-weight: 800;
        color: #000;
        line-height: 30px;
        display: block;
        width: 50%;
        float: right;

        @include respond-to('medium'){
          margin-right: 10px;
        }
      } //nth-of-type(2)
      &:last-of-type{
        color: #fff;
        background-color: $brand-color;
        width: 50%;
        float: left;
        height: 35px;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        border-radius: 4px;
        padding-top: 6px;
        display: block;
        margin-top: 10px;

        @include respond-to('medium'){
          float: left;
          margin-top: unset;
          width: 45%;
        }
        @include respond-to('extra-small'){
          float: left;
          margin-top: 10px;
          width: 100%;
        }
      } //last-of-type
    } //span
  } //result-price
} //results-row

.result-no-price{
  border-right: solid 1px #cecece;
  height: 100%;
  display: block;
  padding: 10px 10px 15px;
  width: 190px;
  float: left;
  text-align: center;
  span{
    font-weight: 600;
    display: block;
    font-size: 20px;
    padding-bottom: 7px;
    padding-top: 7px;

    &:last-of-type{
      font-size: 23px;
      background-color: #116089;
      background: linear-gradient(to left, #116089 0, #317799 65%, #116089 100%);
      color: #fff;
      padding: 15px 0;
      border-radius: 3px;
      text-shadow: 2px 3px 5px rgba(0,0,0,.3);
    }
  }
}

.results-headers{
  margin-bottom: 20px;
  span{
    float: right;
    font-size: 16px;
    color: #777;
    display: block;
    width: 8.3333%;
    text-align: right;
    padding-right: 10px;

    &:first-of-type{
      width: 5%;
      margin-left: 40px;
    } //1st
    &:last-of-type{
      width: 230px;
      float: left;
    } //last
  } //span
} //results-headers
