h2{
  font-size: 26px;
  font-weight: 700;
  color: #000;
  margin-top: 30px;

  @include respond-to('medium'){
    font-size: 22px;
  }
} //h2

.item-data-list{
  width: 100%;
  border-top: solid 1px #cecece;
  margin-bottom: 30px;

  >li{
    border-bottom: solid 1px #cecece;
    height: 40px;

    @include respond-to('medium'){
      height: 33px;
    }

    &:nth-of-type(odd){
      background-color: #f3f3f3;
    } //odd

    ul{
      li{
        display: inline-block;
        position: relative;
        width: 50%;
        padding-right: 18px;

        span{
          display: inline-block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
          color: #000;

          @include respond-to('medium'){
            font-size: 16px;
          }

          &:after{
            content: ':';
          }

        } //span

        &:nth-of-type(2){
          padding-right: 5px;

          span{
            &:after{
              content: '';
            }
            //right: 100px;
          } //span
        } //2nd
      } //li
    } //ul
  } //>li
} //item-data-list

.item-price{
  margin-bottom: 30px;

  span{
    &:first-of-type{
      font-size: 22px;
      color: #777777;
      text-decoration: line-through;
      font-weight: 400;

      @include respond-to('medium'){
        font-size: 16px;
      }

      i{
        font-size: 14px;
        text-decoration: line-through;
      } //i
    } //1st

    font-size: 34px;
    font-weight: 800;
    color: #000;
    line-height: 30px;

    i{
      font-size: 22px;
    }

    @include respond-to('medium'){
      font-size: 25px;
    }
  } //span
} //item-price

.item-contact-btn{
  width: 100%;
  height: 70px;
  background-color: $brand-color;
  border-radius: 5px;
  padding: 10px 18px;
  margin-bottom: 28px;

  span{
    &:first-of-type{
      font-size: 18px;
      color: #ffc600;
      font-weight: 400;
      line-height: 18px;
    } //1st

    font-size: 32px;
    font-weight: 800;
    line-height: 30px;
    color: #fff;
    display: block;

    @include respond-to('medium'){
      font-size: 22px;
    }
  } //span

  @include respond-to('medium'){
    height: 50px;
    padding-top: 3px;
  }
} //item-contact-btn

.item-contact-btn, .item-share{
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0,0,0,0.2);
  transition: .2s;

  &:hover{
    box-shadow: 3px 3px 6px rgba(0,0,0,0.2);
  }

  &:active{
    box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
    position: relative;
    top: 1px;
    left: 1px;
  }
}

.item-share{
  display: inline-block;
  width: 50%;
  height: 50px;
  color: #000;
  font-size: 26px;
  font-weight: 800;
  border-radius: 5px;
  border: solid 1px #116089;
  background-color: #fff;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  @include respond-to('medium'){
    font-size: 20px;
    width: 45%;
    height: 40px;
  }
  @include respond-to('small'){
    margin-bottom: 30px;
  }
} //item-share

.item-call-us{
  display: inline-block;
  float: left;

  @include respond-to('medium'){
    padding-bottom: 3px;
  }

  span{
    &:first-of-type{
      font-size: 18px;
      font-weight: 500;
      margin-bottom: -5px;
    } //1st

    font-size: 25px;
    font-weight: 800;
    color: #000;
    display: block;

    @include respond-to('medium'){
      font-size: 20px;
    }
  } //span
} //item-call-us

.item-picture{

  .item-main-pic{
    display: inline-block;
    width: 79%;
    float: right;
    cursor: pointer;

    @include respond-to('extra-small'){
      width: 100%;
    }

    a{
      display: none;

      &.item-pic-display{
        display: block;
      }
    }
  } //item-main-pic

  ul{
    width: 18%;
    display: inline-block;
    float: left;

    @include respond-to('extra-small'){
      width: 100%;
    }

    li{
      text-align: left;
      margin-bottom: 1px;

      @include respond-to('extra-small'){
        display: inline-block;
        margin: 1% 0 5% 1%;
        width: 31%;

      }

      .item-thumb{
        background-color: #000;
        background-repeat: no-repeat;
        background-blend-mode: luminosity;
        opacity: .5;
        background-size: cover;
        cursor: pointer;

        img{
          opacity: 0;
        } //img
      } //item-th

      &.item-pic-active{

        .item-thumb{
          background-color: unset;
          opacity: 1;
        } //item-thumb
        img{

        }
      } //item-pic-active
    } //li
  } //ul
} //item-picture

.item-features{
  border-bottom: solid 1px #cecece;
  margin: 0 0 40px 0;

  .item-feature{
    border-top: solid 1px #cecece;
    font-size: 16px;
    height: 30px;
    color: #000;
    padding-top: 3px;

    @include respond-to('medium'){
      font-size: 14px;
    }

    &:nth-of-type(odd){
      background-color: #f3f3f3;
    } //odd
  } //item-feature
} //item-features

.contact-form{
  input[type="submit"]{
    height: unset;
    width: unset;
    font-size: 14px;
    border-radius: 4px;
    padding: 6px 12px;
  }
}
