// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.section-full{
  width:100%;
}

.section-limit{
  width:100%;
  max-width:$max-width + ($padding-base * 2);
  margin: 0 auto;
  padding:0px $padding-base;
}

.base-padding{
  padding:0px $padding-base;
}

.section-spacer{
  width:100%;
  height:$section-spacer-height;
}

.row-spacer{
  width:100%;
  float:right;
  height:$row-spacer-height;
}

img{
  max-width:100%;
}

ul{
  padding: 0;
  list-style-type: none;
}

.img-not-responsive{
  max-width:auto;
}

.no-padding{
  padding:0;
}

.no-padding-2{
  padding:0;
}

.no-margin-mobile{
  @include respond-to('medium') {
    margin:0px 15px;
  }
}

.no-margin{
  margin:0;
}


.helper-blue{
  width:100%;
  min-height:250px;
  background-color:blue;
  opacity:0.5
}

.helper-red{
  width:100%;
  min-height:250px;
  background-color:red;
  opacity:0.5
}

.helper-green{
  width:100%;
  min-height:250px;
  background-color:green;
  opacity:0.5
}

.hr-full{
  margin:0;
  padding:0;
  border:0;
  width:100%;
  height:$hr-full-height;
  background-color:$hr-full-bg;
  border-bottom:1px solid $hr-border-color;
  margin:$hr-margin;
}

.no-row-padding{
    margin:0;
    & > *{
      padding:0;
    }
}

.section-limit-30{
  @extend .section-limit;
  max-width:$max-width + ($padding-base * 4);
  padding:0px $padding-base * 2;
  > .row{
    margin: 0px (-$padding-base * 2);
    > div{
      padding:0px $padding-base * 2;
    }
  }
}

.section-limit-45{
  @extend .section-limit;
  max-width: 1110px + ($padding-base * 6);
  padding: 0px $padding-base * 3;
  > .row{
    margin: 0 (-$padding-base * 3);
    > div{
      padding: 0px $padding-base * 3;
    }
  }
}

.no-margin-bottom{
  margin-bottom:0 !important;
}

iframe{
  max-width:100% !important;
}

.full-height{
  height: 100%;
}

.vertical-center{
  position: relative;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
}

#sb-site{
  transform: none;
  
  @include respond-to('small'){
    transform: translate(0);
  }
}
