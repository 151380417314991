.search-box{
  max-width: $max-width - 30px;
  padding: $padding-base;
  margin: $center-margin;
  background: linear-gradient(to bottom, #d5d5d5 0px, #fff 10px, #fff 100px);
  box-shadow: 0 0 25px rgba(0,0,0,0.5);
  border-radius: 0 0 4px 4px;
  position: relative;
  //display: none;

  @include respond-to('medium'){
    padding: 10px;
  }


  .header-seacrh-title{
    color: #000;
    display: inline-block;
    font-weight: 800;
    font-size: 30px;
    width: 22%;
    margin: 0;
    padding-right: 13px;

    @include respond-to('medium'){
      font-size: 25px;
      width: 100%;
      padding: 0;
      margin-bottom: 10px;
    }
    @include respond-to('extra-small'){
      font-size: 20px;
    }
  } //h2


} //search-box

.toggle-search-box{
  transform: translateY(0);
  transition: .3s;
}

.hide-bottom-search{
  transform: translateY(100%);

  .bottom-show-btn{
    opacity: 1 !important;
    pointer-events: all !important;
    width: 175px !important;
  } //bottom-show-btn
} //hide-bottom-search

  .bottom-search-btn{
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    right: 20px;
    width: 40px;
    height: 27px;
    background-color: $btn-color;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border-radius: 5px 5px 0 0;
    cursor: pointer;

    &:before{
      content: '';
      width: 15px;
      height: 15px;
      display: block;
      position: absolute;
      border: solid 3px transparent;
      border-right-color: #fff;
      border-bottom-color: #fff;
      transform: rotate(45deg) translateX(70%);
      top: -20%;
      right: 50%;
    } //after

    .bottom-show-btn{
      position: absolute;
      bottom: 0;
      right: 0px;
      //width: 175px;
      width: 1px;
      height: 27px;
      background-color: $btn-color;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      border-radius: 5px 5px 0 0;
      cursor: pointer;
      padding-right: 40px;
      color: #fff;
      z-index: 2001;
      opacity: 0;
      pointer-events: none;
      transition: .3s;

      &:after{
        content: '';
        width: 15px;
        height: 15px;
        display: block;
        position: absolute;
        border: solid 3px transparent;
        border-right-color: #fff;
        border-bottom-color: #fff;
        transform: rotate(-135deg);
        top: 10px;
        right: 12px;
      } //after
    } //bottom-show-btn
  } //bottom-search-btn



  .search-box-form{
    display: inline-block;
    width: 77%;

    @include respond-to('medium'){
      width: 100%;
      text-align: center;
    }

    .search-select-container{
      display: inline-block;
      width: 27%;
      margin-left: 1.5%;
      position: relative;

      @include respond-to('medium'){
        width: 30%;
      }

      &:after{
        position: absolute;
        content: '';
        background-color: #ededed;
        height: 9px;
        width: 9px;
        top: 50%;
        transform: translateY(-50%);
        left: 2px;
        pointer-events: none;

        @include respond-to('medium'){
          display: none;
        }
      } //after
    } //search-select-container

    select, input[type="submit"]{
      height: 50px;
      border: none;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 400;

      @include respond-to('medium'){
        height: 30px;
        border-radius: 2px;
      }
    } //select, submit

    select{
      width: 100%;
      background-color: #ededed;
      padding: 0px 20px 0 50px;
      background-image: url('../images/select-btn.png');
      background-repeat: no-repeat;
      background-position: 10px center;
      position: relative;
      box-shadow: 3px 2px 5px rgba(0,0,0,0.05) inset;
      border: solid 1px #dfdfdf;
      box-sizing: border-box;
      -moz-appearance: none;

      @include respond-to('medium'){
        background-size: 18px;
        background-position: 5px center;
        padding: 0px 10px 0 20px;
        font-size: 15px;
      }

      @include respond-to('extra-small'){
        background-size: 20px;
        background-position: 5px center;
        padding: 0px 5px 0 10px;
        font-size: 11px;
      }

      &::-ms-expand {
          display: none;
      }
    } //select

    input[type="submit"]{
      width: 13%;
      background-color: $btn-color;
      color: #fff;

      @include respond-to('medium'){
        width: 30%;
        margin: 10px 0 0 4%;
        height: 30px;
        display: block;
        font-size: 14px;
        float: left;
      }

      @include respond-to('extra-small'){
        width: 95%;
        height: 20px;
        float: none;
        margin: 10px auto 0;
      }
    } //input submit
  } //search-box-form




body.show-header-search .bottom-search-box{
  display: none !important;
  @include respond-to('small'){
    display: block !important;
  }
}
