// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

*{
  padding: 0;
  margin: 0;
}

/**
 * Basic styles for links
 */
a {
  color: $main-color;
  text-decoration: none;

  @include on-event {
    color: $brand-color;
    text-decoration: none;
  }
}

.mobile-menu-btn{
  float:left;
}

.site-wrapper{
  width:100%;
  //padding-top:$site-default-top-padding;
  @include respond-to('medium') {
    //padding-top:$site-mobile-default-top-padding;
  }
}

a.selected-link{
  color: $light-red-color;
}
