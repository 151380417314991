.results-content{
  max-width: $max-width;
  padding: $padding-base $padding-base 100px;
  margin: 0 auto;
  position: relative;

  h1{
    font-size: 30px;
    font-weight: 800;
    color: $brand-color;
    margin: 20px 0px;
    padding-bottom: 20px;
    border-bottom: solid 1px #cecece;

    @include respond-to('medium'){
      padding-top: 20px;
      text-align: center;
    }
    @include respond-to('small'){
      padding-top: 0px;
      font-size: 20px;
    }

    span{
      color: #000;
    } //span
  } //h1

  .search-box{
    top:-200px;
    background: unset;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0,0,0,1), 0 0 35px rgba(0,0,0,.5);
    border-radius: 4px 0 4px 4px;
    position: absolute;
    width: 1200px;

    @include respond-to("large"){
      width: 80%;
      top: -100px;
      margin-right: 10%;
    }

    @include respond-to('small'){
      display: none;
    }

    .search-box-title{
      color: #000;
      font-size: 26px;
      font-weight: 700;
      top: -35px;
      right: 15px;
      position: absolute;
      z-index: 1002;

        @include respond-to("large"){
          font-size: 22px;
        }
    } //h2

    .search-box-form{
      width: 100%;
    } //search-box-form

    &:after{
      content: url(../images/searchbox_top.png);
      position: absolute;
      top: 0px;
      right: -16px;
      transform: translateY(-100%);
    } //after

    &:before{
      content: '';
      background-color: #fff;
      position: absolute;
      right: 0;
      top: -1px;
      width: 314px;
      height: 5px;
      border: none;
    }
  } //search-box
} //results-content
