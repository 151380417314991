.header-menu{
  display: inline-block;
  position: relative;
  z-index: 110;

  &>ul{
    padding-top: 12px;
    &>li{
      display: inline-block;
      font-size: 20px;
      color: #aec8d6;
      margin-left: 25px;
      font-weight: 400;

      &:last-of-type{
        margin-left: 0;
      } //last-of-type

      &>a{
        font-size: inherit;
        color: inherit;
      } //a

      &.header-selected, &:hover{
        color: #fff;
      } //header-selected, hover
    } //li
  } //ul
} //header-menu
