.blurred-body{
  filter: blur(0);

  &.blur-active{
    filter: blur(5px);
  }
}

.home-wrapper{
  width: 100%;
}

.home-banner-container{
  width: 100%;
  position: relative;
  z-index: 5;

  @include respond-to('small'){
    margin-top: 70px;
  }

  .home-banner-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0.75) 0%, transparent 50%, transparent 100%);
  } //home-banner-overlay
} //home-banner-container

.home-box{
  width: 100%;
  background-color: #ededed;
  min-height: 500px;
  position: relative;
  z-index: 1100;
  padding: 20px;
  padding-bottom: 85px;

  .home-banner-block{
    position: absolute;
    width: 100%;
    top: 0;
    transform: translateY(-125%);
    text-align: center;

    @include respond-to('extra-small'){
      transform: translateY(-110%);
      right: 0;
    }

    span{
      color: #fff;
      font-size: 36px;
      display: block;

      @include respond-to('medium'){
        font-size: 24px;
      }

      @include respond-to('extra-small'){
        font-size: 16px;
      }

      &:first-of-type{
        font-weight: 800;
        font-size: 44px;
        margin-bottom: -5px;

        @include respond-to('medium'){
          font-size: 30px;
        }

        @include respond-to('extra-small'){
          font-size: 20px;
          margin-bottom: 0;
        }
      } //first-of-type

      &:last-of-type{
        margin-bottom: 30px;

        @include respond-to('medium'){
          font-size: 22px;
        }

        @include respond-to('extra-small'){
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
    } //span
    a{
      color: $brand-color;
      background-color: #fff;
      font-weight: 800;
      font-size: 26px;
      padding: 6px 22px 10px;
      border-radius: 4px;
      display: inline-block;

      @include respond-to('medium'){
        font-size: 20px;
      }

      @include respond-to('extra-small'){
        font-size: 14px;
        padding: 4px 12px 5px;
      }
    } //a
  } //home-banner-block

  .home-box-content{
    max-width: $max-width;
    padding: $padding-base;
    margin: $center-margin;

    .home-box-text{
      background-color: $brand-color;
      width: 100%;
      max-height: 269px;
      box-shadow: 0 0 25px rgba(0,0,0,0.1);
      padding: 25px;
      margin-bottom: 30px;

      @include respond-to('large'){
        overflow: hidden;
        padding: 15px;
        height: 100%;
        max-height: 216px;
      }
      @include respond-to('medium'){

        max-height: unset;
        height: 270px;
      }

      h2{
        color: #fff;
        font-size: 30px;
        font-weight: 800;
        margin: 0;

        @include respond-to('large'){
          font-size: 25px;
        }
      }

      p{
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 15px;

        @include respond-to('large'){
          font-size: 14px;
        }
      }
    } //home-box-text

    .home-box-btn{
      margin-bottom: 30px;
      box-shadow: 0 0 25px rgba(0,0,0,0.1);
      transform: scale(1);
      transition: 0.1s;

      &:hover{
        box-shadow: 0 0 7px rgba(0,0,0,0.25);
        transform: scale(.996);
      }

      .home-btn-img{
        background-size: 100%;
        background-position: center;
        transition: .6s;

        &:hover{
          background-size: 105%;
        } //hover

        img{
          opacity: 0;
        } //img
      } //home-btn-img


      .home-btn-info{
        width: 100%;
        background-color: #fff;
        height: 50px;

          img{
            padding: 15px;
            border-left: solid 1px #dcdcdc;
          } //img

        span{
          margin-right: 20px;
          color: $brand-color;
        } //span
      } //home-btn-info
    } //home-box-btn
    .home-hr{
      height: 2px;
      box-sizing: border-box;
      width: 100%;
      border-top: solid 1px #cecece;
      border-bottom: solid 1px #ffffff;
      display: block;
      margin-top: 5px;
    } //home-hr

    .home-title{
      text-align: center;
      position: relative;
      margin-bottom: -10px;
      margin-top: 0;

      span{
        font-size: 30px;
        font-weight: 800;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        padding: 15px 0 5px;

        @include respond-to('small'){
          font-size: 26px;
        }

        @include respond-to('extra-small'){
          font-size: 15px;
        }

        &:first-of-type, &:last-of-type{
          color: #5b5f6b;
        } //1st, last
        &:nth-of-type(2){
          color: #116089;
        }
      } //span
    } //home-title
  } //home-box-content
} //home-box

.home-bottom-hr{
  width: 100%;
  height: 1px;
  background-color: #cecece;
  margin-top: 10px;
} //home-bottom-hr
