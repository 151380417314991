.main-header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2500;

  .header-desktop{
    width: 100%;
    height: 120px;
    position: relative;
    transition: 0.2s;
    box-shadow: 0 0 0px rgba(0,0,0,0);

    @include respond-to('medium'){
      display: none;
    }

    .header-bg{
      background: linear-gradient(to bottom, #fff 0%, #fff 66.6%, $brand-color 66.6%, $brand-color 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 105;
    } //header-bg

    .header-logo{
      position: relative;
      z-index: 150;
      height: 100%;
      transition: 0.3s;

      img{
        max-height: 100%;
        width: auto;
        transition: 0.3s;
      }
    }

    .header-container{
      max-width: $max-width;
      padding: $padding-base;
      margin: $center-margin;
      height: 100%;
    } //header-container

    .header-upper-row{
      width: 100%;
      height: 66.6%;

      .header-contacts{
        position: relative;
        z-index: 110;
        text-align: left;
        padding-top: 10px;

        span{
          float: left;
          margin-left: 20px;
          margin-top: -5px;
          color: $brand-color;
          font-size: 29px;
          font-weight: 800;
        } //span
        a{
          img{
            float: left;
          } //img
        } //a
      } //header-contacts
    } //header-upper-row
  } //header-desktop

  .search-box-hider{
    display: none;
  }
} //main-header

body.show-header-search .search-box-hider{
  display: block !important; 
}
