footer{
  position: relative;
  left: 0;
  width: 100%;
  background-color: #cfcfcf;
  z-index: 1;

  @include respond-to('medium'){
    height: auto;
  }


  .footer-lower-container, .footer-container{
    width: 1230px;
    background-color: transparent;
    margin: 0 auto;
    position: relative;
  }

  .footer-container{
    padding: 55px $padding-base 0;
    display: flex;
    justify-content: space-between;

    @include respond-to('medium'){
      flex-direction: column;
      width: 100%;
      height: auto;
    }

    .footer-contacts{
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #b5b5b5;
      position: relative;
      height: 100%;
      padding-top: 100px;
      margin-bottom: 20px;

      img{
        position: absolute;
        top: -145px;
        max-width: unset;
        right: -90px
      }

      a{
        color: inherit;
      }

      span{
        display: block;
        margin-bottom: 20px;

        &:first-of-type{
          color: #fff;
        }
      }
    }

    .footer-links{
      display: flex;
      position: relative;
      z-index: 10;

      @include respond-to('medium'){
        flex-wrap: wrap;
        justify-content: center;
      }

      .footer-link-column{
        width: 200px;
        margin-right: 30px;

        .footer-links-title{
          font-size: 18px;
          color: #777777;
          font-weight: 400;
          padding-bottom: 25px;
          display: block;
          margin-bottom: 25px;
          position: relative;
          font-weight: 500;

          .footer-links-border{
            width: 100%;
            position: absolute;
            bottom: 0;
            box-sizing: border-box;
            height: 2px;
            border-top: solid 1px #919191;
            background-color: #e8e8e8;
            overflow: hidden;

            .footer-links-highlight{
              width: 200%;
              height: 100%;
              background: linear-gradient(to left, transparent 0%, $brand-color 50%, $brand-color 100%);
              bottom: 0;
              left: -200%;
              position: absolute;
              transition: left 3s;
            }
          }

          @include respond-to('medium'){
            margin-bottom: 0px;
            padding-bottom: 10px;
          }
        }

        ul{
          @include respond-to('medium'){
            margin-bottom: 30px;
          }
          li{
            a{
              font-size: 16px;
              color: #777777;
              line-height: 30px;
              font-weight: 400;

              &:hover{
                color: $brand-color;
              }
            }
          }
        }

        &:hover{
          .footer-links-title .footer-links-border .footer-links-highlight{
            left: 0;
            transition: .6s;
          }
        }
      }
    }
  } //footer-container
  .footer-lower-container{
    height: 101px;
    padding: 0 $padding-base;
    position: relative;

    a{
      img{
        margin-left: 10px;
        transition: .2s;
        filter: grayscale(0) drop-shadow(0 0 0 rgba(0,0,0,0.3));

        &:hover{
          filter: grayscale(1) drop-shadow(2px 2px 3px rgba(0,0,0,0.3));
        }
      }
    }

    span{
      color: #000;
      font-size: 16px;
      font-weight: 700;
      &:after{
        content: ' | ';
      } //after
      &:first-of-type{
        margin-right: 10px;
      }
      &:last-of-type{
        &:after{
          content: '';
        }
      }
    } //span

    @include respond-to('medium'){
      height: auto;
      max-width: 100%;
      padding-bottom: 50px;
    }
  } //footer-lower-container

  .footer-h-seperator{
    width: 100%;
    height: 0;
    border-bottom: solid 1px #e8e8e8;
    border-top: solid 1px #919191;
    margin-bottom: 30px;
    margin-top: 0px;
  } //footer-h-seperator
} //footer

.footer-contact-form{
  display: block;
  width: 260px;
  text-align: right;
  color: #fff;

  @include respond-to('medium'){
    margin: 0 auto 60px;
  }

  span{
    text-align: right;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    position: relative;

    &:first-of-type{
      color: $brand-color;
      font-size: 26px;
    } //first-of-type

    &:last-of-type{
      top: -5px;
    }
  } //span

  ::-webkit-input-placeholder {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
  ::-moz-placeholder {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
  :-ms-input-placeholder {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
  :-moz-placeholder {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  } //placeholder

  textarea{
    width: 250px;
    border-radius: 3px;
    height: 60px;
    resize: none;
    background-color: #b6b6b6;
    border: solid 1px #dfdfdf;
    padding: 5px 15px;
  } //textarea
  input{
    &[type="text"], &[type="email"], &[type="submit"]{
      width: 250px;
      height: 40px;
      border-radius: 3px;
    } //text, email, submit

    &[type="text"], &[type="email"]{
      background-color: #b6b6b6;
      border: solid 1px #dfdfdf;
      padding: 15px;
      margin-bottom: 5px;
    } //text, email

    &[type="text"]{

    } //text
    &[type="email"]{
    } //email

    &[type="submit"]{
      border: none;
      background-color: $brand-color;
      font-size: 18px;
      box-shadow: 0 0 0 #000;
      margin-bottom: 50px;

      &:active{
        box-shadow: 1px 2px 5px #000 inset;
        outline: none;
      } //active
    } //submit
  } //input
} //footer-contact-form

.footer-soc{
  display: inline;

  @include respond-to('medium'){
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
} //footer-soc
