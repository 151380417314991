.content-wrapper{
  width: 100%;
  position: relative;

  .content-banner{
    position: relative;
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @include respond-to('medium'){
      background-position: 0 70px;
      top: 70px;
    }
    @include respond-to('small'){
      background-position: 0 70px;
      top: 70px;
    }

    img{
      opacity: 0;
    } //img
  } //content-banner

  .content-hugger{
    position: relative;
    background-color: #fff;

    @include respond-to('small'){
      background-position: 0 70px;
      top: 70px;
    }

    .content-content{
      max-width: $max-width;
      margin: 0 auto;
      padding-bottom: 100px;

      @include respond-to('large'){
        padding: 10px 15px 100px;
      }

      .content-main{
        padding-left: 35px;

        h1{
          color: $brand-color;
          font-size: 30px;
          font-weight: 800;
          padding-bottom: 30px;
          border-bottom: solid 1px #cecece;
          margin-bottom: 30px;

          @include respond-to('extra-small'){
            font-size: 22px;
            margin-bottom: 20px;
            padding-bottom: 20px;
          }
        } //h1

        .content-summary{
          p{
            font-size: 22px;
            font-weight: 700;
            color: #000;
            margin-bottom: 20px;

            @include respond-to('extra-small'){
              font-size: 16px;
            }
          } //p
        } //content-summary
        .content-text{
          padding-bottom: 20px;

          p{
            font-size: 18px;
            color: #000;
            margin: 20px 0;

            @include respond-to('extra-small'){
              font-size: 14px;
            }
          } //p
        } //content-text
      }//content-main

      .content-left{
        h2{
          font-size: 24px;
          font-weight: 800;
          color: #000;
          padding-bottom: 30px;
          border-bottom: solid 1px #cecece;
          margin-bottom: 0px;
          margin-top: 27px;

          @include respond-to('extra-small'){
            font-size: 18px;
          }
        } //h2

        ul{
          li{
            border-bottom: solid 1px #cecece;
            padding: 20px 15px 20px 0;
            position: relative;

            @include respond-to('extra-small'){
              padding-top: 10px;
              padding-bottom: 10px;
            }

            img{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              pointer-events: none;
            }

            h3{
              color: $brand-color;
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 20px;
              margin-top: 0;

              @include respond-to('extra-small'){
                font-size: 16px;
                margin-bottom: 10px;
              }
            } //h3

            p{
              color: #000;
              font-size: 18px;
              text-align: justify;
              line-height: 18px;

              @include respond-to('extra-small'){
                font-size: 14px;
              }
            } //p
          } //li
        } //ul
      } //content-left
    } //content-content
  } //content-hugger
} //content-wrapper
