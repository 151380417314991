.bottom-search-box{
  position: fixed;
  bottom: 0;
  z-index: 2000;
  width: 100%;
  opacity: 0;

  .search-box{
    background: unset;
    background-color: #fff !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border-radius: 0;
    border-top: solid 1px $btn-color;
    display: none;

    @include respond-to('small'){
      display: block;
    }

    &:after{
      content: '';
      background-color: #fff;
      width: 200px;
      height: 15px;
      position: absolute;
      top: 0px;
      right: 10px;
    }
  } //search-box


} //bottom-search-box
